import React from "react";

import Slider from "../../Carousel2/Slider";
import Productos from "../Productos/Productos";
import Empresa from "../About/About";
import Contacto from "../Contacto/Contacto";
import imageSlider from "../../Carousel2/imageSlider";
import "./home.css";
function Home() {
  return (
    <div id="home">
      <Slider imageSlider={imageSlider} />
      <div className="bodyHome">
        <Productos />
      </div>
      <Empresa />
      <Contacto />
    </div>
  );
}

export default Home;
