import fondoMina from "../../../Media/Img/backgrounds/fondo_mina.jpg";
import tolvaPng from "../../../Media/Img/carrocerias/tolva-min.png";
import tolva1 from "../../../Media/Img/carrocerias/tolva1-min.png";
import tolva2 from "../../../Media/Img/carrocerias/tolva2-min.png";
import tolva3 from "../../../Media/Img/carrocerias/tolva3-min.png";
import tolva4 from "../../../Media/Img/carrocerias/tolva4-min.png";
import tolva5 from "../../../Media/Img/carrocerias/tolva5-min.png";
import tolva6 from "../../../Media/Img/carrocerias/tolva6-min.png";

import ramflaPlat1 from "../../../Media/Img/carrocerias/ramflas/ramfla-plat1-min.png";
import ramflaPlat2 from "../../../Media/Img/carrocerias/ramflas/ramfla-plat2-min.png";
import ramflaPlat3 from "../../../Media/Img/carrocerias/ramflas/ramfla-plat3-min.png";
import ramflaPlat4 from "../../../Media/Img/carrocerias/ramflas/ramfla-plat4-min.png";
import ramflaPlat5 from "../../../Media/Img/carrocerias/ramflas/ramfla-plat5-min.png";
import ramflaPlat6 from "../../../Media/Img/carrocerias/ramflas/ramfla-plat6-min.png";
import ramflaPlat7 from "../../../Media/Img/carrocerias/ramflas/ramfla-plat7-min.png";
import ramflaPlat8 from "../../../Media/Img/carrocerias/ramflas/ramfla-plat8-min.png";

export const dataCarrocerias = {
  "tolva-semiremolque-volquete-metalero-encapsulado": {
    categoria: "CARROCERIAS",
    subcategoria: "TOLVAS",
    subTitle: "SEMIREMOLQUES Y VOLQUETES",
    description:
      "Fabricación de Carrocerías Metálicas para Vehículos de Carga Diseñamos y fabricamos carrocerías de alta resistencia como tolvas encapsuladas y graneleras. Soluciones duraderas y adaptadas a las necesidades de cada industria.",
    imgTitleContainer: fondoMina,
    imgMain: tolvaPng,
    tabData: {
      "Tolvas Encapsuladas": [
        {
          title: "CARROCERIAS",
          modelo: "TOLVA ENCAPSULADA",
          description:
            " El diseño cerrado protege la carga de elementos externos como la lluvia, el viento y el polvo, manteniendo la calidad del material transportado.",
          imgSrc: tolva1,
        },

        {
          title: "CARROCERIAS",
          modelo: "TOLVA ENCAPSULADA",
          description:
            " Equipada con mecanismos de apertura y cierre seguros para una descarga eficiente y controlada del material.",
          imgSrc: tolva3,
        },
        {
          title: "CARROCERIAS",
          modelo: "TOLVA ENCAPSULADA",
          description:
            " Construida con materiales resistentes para soportar grandes cargas, ideal para transporte de materiales pesados y de volumen.",
          imgSrc: tolva4,
        },
        {
          title: "CARROCERIAS",
          modelo: "TOLVA ENCAPSULADA",
          description:
            " Su diseño permite una carga máxima dentro de sus dimensiones, aprovechando al máximo el espacio para transportar más material por viaje.",
          imgSrc: tolva2,
        },
        {
          title: "CARROCERIAS",
          modelo: "TOLVA ENCAPSULADA",
          description:
            " Equipadas con mecanismos de descarga que pueden ser por compuertas o sistemas neumáticos, dependiendo de la carga y el tipo de producto.",
          imgSrc: tolva6,
        },
      ],
      "Tolvas Graneleras": [
        {
          title: "CARROCERIAS",
          modelo: "TOLVA GRANELERA",
          description:
            " Diseñadas para transportar grandes volúmenes de productos a granel, como granos, fertilizantes, cemento, y otros materiales sueltos. Su capacidad de carga es óptima para maximizar el espacio disponible.",
          imgSrc: tolva5,
        },
        {
          title: "CARROCERIAS",
          modelo: "TOLVA GRANELERA",
          description:
            " Sistemas de apertura controlada en la parte inferior, que permiten una descarga rápida y controlada del material transportado, ideal para optimizar tiempos de carga y descarga en operaciones logísticas.",
          imgSrc: tolvaPng,
        },
      ],
    },
  },

  "plataformas-ramflas-baranda-rebatible": {
    categoria: "CARROCERIAS",
    subcategoria: "RAMFLAS Y PLATAFORMAS",
    subTitle: "SEMIREMOLQUES",
    description:
      "Carrocerías robustas y versátiles con barandas livianas, ideales para minería y construcción. Adaptadas para cargas paletizadas y contenedores, nuestras plataformas ofrecen estabilidad y resistencia con opciones de suspensión de muelle o neumática, facilitando carga y descarga.",
    imgTitleContainer: fondoMina,
    imgMain: ramflaPlat7,
    tabData: {
      "Ramflas Baranda Rebatible": [
        {
          title: "CARROCERIAS",
          modelo: "RAMFLAS BARANDA REBATIBLE",
          description:
            " Versatilidad en el Transporte. Las barandas rebatibles permiten la carga y descarga lateral, ideal para el transporte de materiales paletizados y contenedores",
          imgSrc: ramflaPlat2,
        },

        {
          title: "CARROCERIAS",
          modelo: "RAMFLAS BARANDA REBATIBLE",
          description:
            " Resistencia y Durabilidad. Construidas con materiales de alta resistencia, diseñadas para soportar condiciones exigentes en sectores como minería y construcción.",
          imgSrc: ramflaPlat4,
        },
        {
          title: "CARROCERIAS",
          modelo: "RAMFLAS BARANDA REBATIBLE",
          description:
            " Suspensión Adaptable. Disponibles con sistemas de suspensión neumática o de muelle, brindando mayor estabilidad y confort en el transporte.",
          imgSrc: ramflaPlat6,
        },
        {
          title: "CARROCERIAS",
          modelo: "RAMFLAS BARANDA REBATIBLE",
          description:
            " Facilidad de Operación. Diseño que facilita la manipulación y asegura una rápida carga y descarga, optimizando los tiempos operativos.",
          imgSrc: ramflaPlat7,
        },
      ],
      Plataformas: [
        {
          title: "CARROCERIAS",
          modelo: "PLATAFORMA",
          description:
            " Capacidad de Carga Versátil. Ideales para transportar cargas pesadas, maquinaria y materiales diversos gracias a su superficie plana y resistente.",
          imgSrc: ramflaPlat1,
        },
        {
          title: "CARROCERIAS",
          modelo: "PLATAFORMA",
          description:
            " Estructura Robusta. Fabricadas con materiales de alta durabilidad para soportar condiciones exigentes, especialmente en sectores industriales y de construcción.",
          imgSrc: ramflaPlat3,
        },
        {
          title: "CARROCERIAS",
          modelo: "PLATAFORMA",
          description:
            " Facilidad de Carga y Descarga. Al carecer de laterales elevados, permiten una carga y descarga sencilla desde cualquier ángulo, optimizando tiempos de operación.",
          imgSrc: ramflaPlat5,
        },
        {
          title: "CARROCERIAS",
          modelo: "PLATAFORMA",
          description:
            " Compatibilidad con Diversos Tipos de Suspensión. Disponibles con suspensiones de muelle o neumáticas para adaptarse a diferentes necesidades de transporte y ofrecer estabilidad en el camino.",
          imgSrc: ramflaPlat8,
        },
      ],
    },
  },
  // Agrega más tipos aquí...
};
