import styled from "styled-components";
import { colors, fonts } from "../../../helpers/enviroments";
import { Link as LinkS } from "react-router-dom";

export const CardProduct = styled.div`
  width: 350px;
  border-radius: 12px 12px 12px 12px;
  border: 0px solid black;
  box-shadow: 0px 2px 10px 0px rgb(102, 102, 102);
  background: white;
  z-index: 120;
  transition: transform 0.3s ease;
  &:hover {
    div i {
      transform: scaleX(-1) rotate(90deg) scale(1.8);
    }
  }
  img {
    width: 100%;
    height: 100%; /* Llena completamente el div */
    object-fit: cover; /* Cubre el contenedor sin distorsionar la imagen */
    border-radius: 12px 12px 0 0;
  }

  .nombre-producto h2 {
    color: ${colors.grayBold};
    padding: 10px 20px 0 20px;
    font-family: ${fonts.main};
    text-align: start;
    font-weight: lighter;
    font-size: 2rem;
    line-height: 25px;
    letter-spacing: 1.1px;
    font-weight: 800;
    text-transform: uppercase;
    /* background: white; */
  }
  .descripcion-producto p {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.2em;
    color: gray;
    font-size: 15px;
    text-align: start;
    padding: 5px 20px 20px 0px;
    background: white;
  }
  .conocer-mas {
    position: relative;
    color: ${colors.grayBold};
    text-transform: uppercase;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 20px 0 20px;
    justify-content: space-between;
    background: ${colors.mainColor};
    border-radius: 0 0 12px 12px;
    min-height: 50px;
    font-size: 1.5rem;
    cursor: pointer;
    overflow: hidden;
    &:hover {
      div i {
        transform: scaleX(-1) rotate(90deg) scale(1.8);
      }
    }

    div a {
      position: absolute;
      width: 100%;
      height: 100%;
      align-content: center;
      padding-left: 20px;

      color: ${colors.grayLigth};
      z-index: 2; /* Asegura que el texto esté por encima del fondo */
      text-decoration: none;
      top: 50%; /* Centro vertical */
      left: 50%; /* Centro horizontal */
      transform: translate(-50%, -50%);
      font-weight: bold;
      opacity: 1; /* Asegura que el texto tenga opacidad 1 */
      transition: opacity 0.3s; /* Transición suave para el texto si se desea */
    }
    div i {
      color: ${colors.grayBold};
      transform: scaleX(-1);
      transition: transform 0.4s ease;
    }
  }
  &:hover .icon-hover i {
    transform: scaleX(-1) rotate(90deg) scale(1.8);
  }
`;

export const TabButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: ${colors.gray};
`;
export const LinkButton = styled(LinkS)`
  text-decoration: none;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  clip-path: polygon(15% 0, 100% 0, 85% 100%, 0% 100%); /* Extiende la esquina superior derecha más allá */
  width: 250px;
  font-size: 1.3rem;
  font-weight: 800;
  height: 80px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: ${colors.mainColor};
  color: ${colors.grayBold};
  border: none;
  outline: none;
  /* font-size: 1rem; */
  transition: 0.2s ease-in;

  &:hover {
    color: ${colors.grayLigth};
  }
`;
