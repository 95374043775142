import styled, { keyframes } from "styled-components";
import { colors, fonts } from "../../../helpers/enviroments";

export const MainContainer = styled.div``;

export const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  /* border: 10px solid #ccc; */
  img {
    width: 100%; /* La imagen ocupará todo el ancho del div */
    height: 100%; /* La imagen ocupará toda la altura del div */
    object-fit: cover;
    filter: blur(0.5px);
    filter: brightness(0.8);
  }
  .text-overlay {
    /* width: 100%; */
    /* border: 10px solid #ccc; */
    position: absolute; /* Permite colocar el texto sobre la imagen */
    top: 50%; /* Centro vertical */
    left: 50%; /* Centro horizontal */
    transform: translate(-90%, -50%); /* Ajusta el texto al centro exacto */
    color: white; /* Color del texto */
    text-align: center; /* Centrar el texto */
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    padding: 20px; /* Espaciado interno */
    border-radius: 5px; /* Bordes redondeados opcional */
    text-align: start;
    z-index: 200;
  }

  .text-overlay h1 {
    font-style: italic;
    font-size: 3rem;
    line-height: 1;
    padding: 10px 0 10px 0;
    color: ${colors.mainColor};
    margin: 0; /* Eliminar margenes del título */
  }

  .text-overlay h2 {
    border-radius: 10px;
    display: inline-block;
    padding: 10px;
    background: ${colors.mainColor};
    color: ${colors.grayLigth};
    font-weight: bolder;
    margin: 0 0 10px 0;
  }

  .text-overlay p {
    font-style: italic;
    font-weight: 500;
  }

  .img-container {
    height: 400px;
    width: 600px;
    /* border: 10px solid #ccc; */
    position: absolute; /* Permite colocar el texto sobre la imagen */
    top: 50%; /* Centro vertical */
    left: 50%; /* Centro horizontal */
    transform: translate(-5%, -40%); /* Ajusta el texto al centro exacto */
    color: white; /* Color del texto */
    text-align: center; /* Centrar el texto */

    padding: 10px; /* Espaciado interno */
    border-radius: 5px; /* Bordes redondeados opcional */
    text-align: start;
    z-index: 100;
  }

  .img-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media (max-width: 768px) {
    .text-overlay {
      width: 95%;
      top: 30%; /* Centro vertical */
      left: 50%; /* Centro horizontal */
      transform: translate(-50%, -50%); /* Ajusta el texto al centro exacto */
    }
    .img-container {
      height: 250px;
      width: 350px;
      top: 50%; /* Centro vertical */
      left: 50%;
      transform: translate(-50%, 10%);
    }
    .text-overlay h1 {
      font-size: 2.5rem;
    }
  }
`;

// Animación para deslizar el contenido desde la derecha
const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(20%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const TabContainer = styled.div`
  /* border: 10px solid #ccc; */
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  width: 100%;
  margin: auto;
  text-align: center;
`;
export const TabButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: ${colors.gray};
`;
export const TabButton = styled.button`
  clip-path: polygon(15% 0, 100% 0, 85% 100%, 0% 100%); /* Extiende la esquina superior derecha más allá */
  width: 250px;
  font-size: 1.3rem;
  font-weight: 800;
  height: 80px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? colors.mainColor : "#ccc")};
  color: ${(props) => (props.active ? "white" : "#333")};
  border: none;
  outline: none;
  /* font-size: 1rem; */
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.active ? colors.mainColorLight : "#aaa")};
  }
`;

export const TabContent = styled.div`
  width: 100%;
  display: ${(props) => (props.active ? "block" : "none")};
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  /* margin-top: 10px; */
  opacity: 0;
  animation: ${(props) => (props.active ? slideIn : "none")} 0.5s forwards;
`;

// ###################
export const TabContentContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 120px;
  background: ${colors.blueColor};
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ContentBlock = styled.div`
  display: flex;
  /* flex-wrap: nowrap; */
  align-items: center;
  /* justify-content: ${(props) => (props.alignRight ? "end" : "start")}; */
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1); /* Fondo semitransparente */
  padding: 80px 10px 80px 10px; /* Espaciado interno */
  border-radius: 5px; /* Bordes redondeados opcional */
  /* flex-direction: ; */
  /* Alternar el orden de los elementos para la segunda sección */
  /* &:nth-child(1) {
    flex-direction: row-reverse;
  } */
  flex-direction: ${(props) => (props.alignRight ? "row-reverse" : "row")};
  @media (max-width: 768px) {
    flex-direction: column;
    &:nth-child(2) {
      flex-direction: column;
    }
  }
`;

export const TextContainer = styled.div`
  border: 5px solid ${colors.gray};
  /* height: 200px; */
  position: relative;
  min-width: 300px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: ${(props) => (props.alignRight ? "left " : "right")};
  align-items: ${(props) => (props.alignRight ? "flex-start" : " flex-end")};

  background: rgba(200, 200, 200, 0.1); /* Fondo semitransparente */
  padding: 20px; /* Espaciado interno */
  margin-bottom: 10px;
  border-radius: ${(props) => (props.alignRight ? "35px 0px 35px 0" : " 0 35px 0px 35px")};

  @media (max-width: 768px) {
    border-radius: ${(props) => (props.alignRight ? "45px 0px 45px 0" : " 0 45px 0px 45px")};
  }
`;

export const Title = styled.h1`
  font-family: ${fonts.normal};
  color: white;
  font-size: 2rem;
  margin: 0;
`;
export const SubtitleContent = styled.div`
  /* border: 10px solid #ccc; */
  background: ${colors.mainColor};
  margin: 0 0 10px 0;
  border-radius: 10px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const Subtitle = styled.h2`
  /* position: absolute; */
  font-family: ${fonts.main};

  color: white;
  font-size: 3rem;

  display: inline-block;
  padding: 10px;
  line-height: 50px;
  color: white;
  font-weight: bolder;

  @media (max-width: 768px) {
    line-height: 40px;
    font-size: 2.5rem;
  }
`;

export const Description = styled.p`
  font-size: 1rem;
  color: white;
  font-size: 1.4rem;
  i {
    color: ${colors.mainColor};
  }
`;

export const ImageContainer = styled.div`
  /* border: 10px solid #ccc; */
  /* min-width: 500px; */
  max-width: 50%;
  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

export const Image = styled.img`
  width: 100%;
  padding: 15px;
  height: auto;
  border-radius: 8px;
  filter: brightness(1.2);
  @media (max-width: 768px) {
    padding: 0px;
  }
`;
