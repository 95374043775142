import styled from "styled-components";

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.96);
  z-index: 250;

  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 960px) {
    overflow: scroll;
    overflow-x: hidden;
  }
`;
export const ContenedorModal = styled.div`
  width: 1200px;
  min-height: 300px;
  /* background: #fff; */
  /* padding: 15px; */
  position: relative;
  border-radius: 5px;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  /* padding: 20px; */
  /* top: 80px; */
  @media screen and (max-width: 960px) {
    width: 100vw;
    /* padding:8px; */
    height: 100vh;
  }
`;
export const EncabezadoModal = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e8e8e8;
  font-family: "Days One", sans-serif;

  h3 {
    font-weight: 100;
    font-size: 35px;
    /* color: #1766DC; */
    color: #fff;
  }
  img {
    width: 80px;
    padding-left: 5px;
    padding-right: 10px;
  }
  @media screen and (max-width: 960px) {
    padding: 40px;
    h3 {
      font-size: 25px;
    }
  }
`;
export const BotonCerrar = styled.button`
  position: absolute;
  right: 10px;
  top: 0px;
  background: #000;
  font-size: 50px;
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #ffc500;

  /* &:hover{
        background: #F2F2F2;
    } */
  svg {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 960px) {
    right: 30px;
    top: 14px;
  }
`;
export const ContenidoModal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  /* border: solid 1px #000; */
  text-align: start;
  /* height: 300px; */
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    vertical-align: top;
    border-radius: 3px;
  }
  transition: 1s ease all;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
export const WrapModal = styled.div`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  transition: 0.2s ease all;
  @media screen and (max-width: 960px) {
    width: 100%;
    flex-direction: column;
  }
`;
export const WrapModalImg = styled.div`
  width: 50%;
  text-align: center;
  transition: 0.2s ease all;
  @media screen and (max-width: 960px) {
    padding-top: 2rem;
    /* padding-bottom: 4rem; */
    width: 100vw;
  }
`;
export const WrapContent = styled.div`
  text-align: start;
  width: 50%;
  /* border: solid 1px #000; */
  display: flex;
  flex-direction: column;
  padding: 2px;
  transition: 0.2s ease all;
  @media screen and (max-width: 960px) {
    width: 100vw;
    /* padding: 0px 0px 0px ; */
    padding-left: 10px;
    padding-right: 5px;
  }
`;
export const SubtitleContent = styled.div`
  color: #ffc500;
  /* font-family: "Noto Sans", sans-serif; */
  font-weight: 400;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
`;
export const SubtitleText = styled.div`
  /* border: 1px solid red; */
  padding-top: 5px;
  h2 {
    font-weight: 600;
  }
  i {
    font-size: 25px;
    padding-right: 5px;
  }
  @media screen and (max-width: 960px) {
    font-size: 15px;
  }
`;

export const DetContent = styled.div`
  text-align: start;
  padding: 2px 8px 2px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  @media screen and (max-width: 960px) {
    font-size: 14px;
  }
`;
export const DetContainerDesc = styled.div`
  /* border: 1px solid red; */
  color: #fff;
  font-family: "Noto Sans", sans-serif;
  font-weight: 100;

  i {
    font-size: 6px;
    padding-left: 5px;
    padding-right: 10px;
  }
`;
