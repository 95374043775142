import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { FaTimes, FaBars, FaCaretDown } from "react-icons/fa";
import { Link as LinkS } from "react-scroll";
import { colors } from "../../helpers/enviroments";
// const COLORS = {
//   primary: 'black',
//   background: 'white',

// };

export const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? "#000" : "transparent")};
  background: #d1d1d1;
  height: 80px;
  width: 100%;
  display: flex;

  align-items: center;
  font-size: 1.2rem;
  position: sticky;

  -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #cb9c0d;
  box-shadow: 0 0px 6px -2px #cb9c0d;
  /* border-bottom: 4px solid  #CB9C0D; */
  top: 0;
  z-index: 240;
  @media screen and (max-width: 960px) {
    /* position: relative; */
    /* background: #D1D1D1; */
  }
`;
export const LogoContainer = styled.div`
  width: 400px;
  display: flex;
  /* flex-wrap: nowrap; */
  justify-content: start;
  align-items: center;
  /* border: 4px solid #cb9c0d; */
  h1 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  h1::first-letter {
    color: ${colors.mainColor};
    font-size: 2.2rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
  @media screen and (max-width: 760px) {
    h1 {
      font-size: 1.5rem;
    }
    h1::first-letter {
      color: ${colors.mainColor};
      font-size: 1.8rem;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    }
  }
`;
export const LogoLink = styled(Link)`
  color: #3b3939;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  @media screen and (max-width: 960px) {
    /* position: absolute; */

    /* transform: translate(25%, 50%); */
  }
`;
export const MenuIcon = styled.div`
  display: none;
  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
export const FasI = styled.div``;
export const CloseIcon = styled(FaTimes)`
  color: #000;
  font-size: 2rem;
`;
export const BarIcon = styled(FaBars)`
  color: #000;
  font-size: 2rem;
`;
export const AngleDown = styled(FaCaretDown)`
  color: #3b3939;
`;

export const NavMenuResp = styled.ul`
  width: 100%;
  height: 100vh;
  top: 80px;
  left: -100%;
  position: absolute;
  ${({ active }) => {
    if (active) {
      return css`
        background: #242222;
        z-index: 1;
        left: 0%;
      `;
    }
  }}
`;
export const NavMenu = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 2.5rem;
  list-style: none;
  text-align: center;
  width: 70%;
  justify-content: end;
  margin-right: 2rem;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: initial;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: -100%;
    /* left: ${({ active }) => (active ? "0" : "-100%")} ; */
    opacity: 1;
    transition: all 0.5s ease;
    ${({ active }) => {
      if (active) {
        return css`
          background: #d1d1d1;
          z-index: 1;
          left: 0%;
        `;
      }
    }}
  }
`;

export const NavItem = styled.li`
  display: flex;
  align-items: center;
  height: 80px;
  /* width: 100px; */
  @media screen and (max-width: 960px) {
    padding: 1em;
  }
`;
export const NavLinkMobile = styled(Link)`
  /* @import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap'); */
  /* font-family: 'Kanit', sans-serif; */
  display: none;
  padding: 1rem;
  margin: 2rem auto;
  @media screen and (max-width: 960px) {
    display: block;
    text-align: center;
    padding: 1rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #e5e2d9;
    font-size: 3rem;

    &:hover {
      background: #e5e2d9;
      color: #1888ff;
      transition: 250ms;
    }
  }
`;
export const NavLink = styled(LinkS)`
  /* font-family: 'PT Sans', sans-serif; */
  font-family: "Teko", sans-serif;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 25px;
  color: #000;
  text-decoration: none;
  margin: 0.3rem;
  /* padding: 0.3rem; */
  border-bottom: 5px solid transparent;
  cursor: pointer;
  &:hover {
    /* text-decoration: underline white; */
    border-radius: 1px;
    border-bottom: 10px solid #cb9c0d;
    color: #cb9c0d;
    transition: all 0.2s ease-out;
  }
  &.active {
    border-bottom: 3px solid #cb9c0d;
  }
  @media screen and (max-width: 960px) {
    display: block;
    text-align: center;
    padding: 1.5rem;
    width: 100%;
    display: table-column;
    font-size: 2.5rem;
    &:hover {
      background-color: #1888ff;
      border-radius: 4px;
    }
  }
`;
export const Button = styled.button`
  &:root {
    --primary: #1888ff;
  }
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: 2px solid transparent;
  font-size: 18px;
  color: #e5e2d9;
  cursor: pointer;
  background-color: #1888ff;

  &:hover {
    /* padding: 8px 20px; */
    transition: all 0.1s ease-in-out;
    background-color: transparent;
    color: #e5e2d9;
    border-radius: 4px;
    border: 2px solid #e5e2d9;
  }
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
export const LogoImg = styled.img`
  height: 50px;
  @media screen and (max-width: 960px) {
    height: 40px;
  }
  /* padding: 5px; */
`;
