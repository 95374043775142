import React from "react";
import "./about.css";
import portada1 from "../../../Media/Img/empresa/portada1.jpg";

import {
  MainContainer,
  ContainerAbout,
  ContainerTitle,
  ContainerInfo,
  TopTile,
  TitleEmpresa,
  BotTile,
  WraperHistoria,
  SubtitleHistoria,
  TextHistoria,
  ImgEmpresa,
  WraperValoresMision,
  CardValores,
  CardMision,
  PreParrafo,
  CardRightEmp,
  TextCap,
  TextCapacidades,
  BoxRoundText,
  IframeAwesome,
  ContainerVideo,
  ImgEmpresaSec,
} from "./aboutElements";

function About() {
  return (
    <MainContainer id="empresa">
      <ContainerAbout>
        <ContainerTitle>
          <TitleEmpresa>FABRICACIONES INNOVA</TitleEmpresa>
          <WraperHistoria>
            <h2>INNOVA: Empresa líder en la industria metalmecánica en Perú</h2>
            <p>
              Con una sólida trayectoria en el mercado, <strong>INNOVA</strong> se ha posicionado como una empresa líder
              en el sector <strong>metalmecánico</strong>. Nos especializamos en la{" "}
              <strong>fabricación de carrocerías</strong> para el transporte de mercancías, tanto para el sector{" "}
              <strong>minero</strong> como para otros sectores industriales en todo el Perú.
            </p>
          </WraperHistoria>

          <WraperHistoria>
            <h2>Calidad, Innovación y Respaldo</h2>
            <p>
              En <strong>INNOVA</strong>, combinamos innovación con los más altos <strong>estándares de calidad</strong>{" "}
              para ofrecer productos robustos y duraderos. Nuestro equipo de especialistas cuenta con años de
              experiencia, lo que nos permite diseñar soluciones personalizadas para las necesidades de cada cliente.
            </p>
            <p>
              Atendemos a importantes empresas nacionales en sectores como <strong>minería</strong>,{" "}
              <strong>transporte</strong> y <strong>logística</strong>, y contamos con el respaldo de una amplia base de{" "}
              <strong>clientes satisfechos</strong> a nivel local y nacional.
            </p>
          </WraperHistoria>

          <WraperHistoria>
            <h2>¿Por qué elegirnos?</h2>
            <p>
              En <strong>INNOVA</strong>, somos líderes en la <strong>fabricación de carrocerías</strong> y{" "}
              <strong>semirremolques</strong> para la <strong>industria metalmecánica</strong> en Perú. Ofrecemos
              soluciones innovadoras de alta calidad que cumplen con las exigencias del sector del{" "}
              <strong>transporte de carga pesada</strong>.
            </p>
            <br />
            <TextHistoria></TextHistoria>
            <br />
            <TextHistoria></TextHistoria>
            <ImgEmpresa src={portada1}></ImgEmpresa>
          </WraperHistoria>
        </ContainerTitle>

        <div class="informacion">
          <div class="mision">
            <div class="icono-circulo-sombra">
              <i class="fas fa-bullseye"></i>
            </div>
            <p class="descripcion">
              <strong>Nuestra misión </strong> es proporcionar soluciones innovadoras y de alta calidad para la
              industria metalmecánica.
            </p>
          </div>

          <div class="vision">
            <div class="icono-circulo-sombra">
              <i class="fas fa-eye"></i>
            </div>
            <p class="descripcion">
              <strong>Nuestra visión </strong> es ser líderes en el mercado nacional e internacional, ofreciendo
              productos que superen las expectativas de nuestros clientes.
            </p>
          </div>
          <div class="mision">
            <div class="icono-circulo-sombra">
              <i class="fa-solid fa-heart"></i>
            </div>
            <p class="descripcion">
              <strong>Honestidad: </strong> Una de nuestras cualidades el cual mantenemos firme en nuestra vida
              cotidiana, con nuestros clientes y en cada uno de nuestros proyectos, el cual nos impulsa a cumplir con
              nuestras obligaciones, así mismo damos todo de nosotros para cumplir lo que ofrecemos.
            </p>
          </div>
          <div class="mision">
            <div class="icono-circulo-sombra">
              <i class="fas fa-clock"></i>
            </div>
            <p class="descripcion">
              <strong>Responsabilidad: </strong> Consideramos una cualidad y un valor importante en el ser humano es por
              ello Nuestro compromiso es cumplir acuerdos y obligaciones y asumir responsabilidades.
            </p>
          </div>
          <div class="mision">
            <div class="icono-circulo-sombra">
              <i class="fas fa-tasks"></i>
            </div>
            <p class="descripcion">
              <strong>Trabajo en Equipo: </strong> Todos miramos hacia un solo objetivo, Uno de los pilares importantes
              en nuestra Empresa es el trabajo en equipo, agradecemos el gran aporte que dan día a día nuestros
              colaboradores, puesto que el trabajo en equipo nos a permitido ser mas eficientes en nuestro desempeño y
              así hemos logrado alcanzar muchas metas y objetivos trazados.
            </p>
          </div>
        </div>

        <ContainerInfo>
          <h2>
            <i class="fa-solid fa-check"></i> CAPACIDADES
          </h2>
          <div class="capacidades">
            <div class="capacidad">
              <div class="icono-circulo">
                <i class="fa-solid fa-helmet-safety"></i>
              </div>
              <p>
                <strong>Personal profesional y técnico altamente calificado.</strong>
              </p>
            </div>
            <div class="capacidad">
              <div class="icono-circulo">
                <i class="fa-solid fa-certificate"></i>
              </div>
              <p>
                <strong>Soldadores Homologados y Certificados.</strong>
              </p>
            </div>
            <div class="capacidad">
              <div class="icono-circulo">
                <i class="fas fa-shield-alt"></i>
              </div>
              <p>
                <strong>Productos de alta calidad, diseño y seguridad.</strong>
              </p>
            </div>
            <div class="capacidad">
              <div class="icono-circulo">
                <i class="fa-solid fa-screwdriver"></i>
              </div>
              <p>
                <strong>Garantía y servicios post venta.</strong>
              </p>
            </div>
            <div class="capacidad">
              <div class="icono-circulo">
                <i class="fa-solid fa-user-check"></i>
              </div>
              <p>
                <strong>Pensamos en las necesidades del cliente.</strong>
              </p>
            </div>
            <div class="capacidad">
              <div class="icono-circulo">
                <i class="fa-solid fa-building-wheat"></i>
              </div>
              <p>
                <strong>INNOVA® lo componen empresas socialmente responsables.</strong>
              </p>
            </div>
          </div>
          <CardRightEmp>
            <div class="video-grid">
              <div class="video-container">
                <iframe
                  // width="560"
                  // height="315"
                  // style={{ margin: "2rem 0" }}
                  src="https://www.youtube.com/embed/VRori6qhM8g"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>

              <div class="video-container">
                <iframe
                  src="https://www.youtube.com/embed/DNcQrRMQczg"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            {/* <ContainerVideo>
              <iframe
                width="560"
                height="315"
                style={{ margin: "2rem 0" }}
                src="https://www.youtube.com/embed/VRori6qhM8g"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </ContainerVideo>
            <ContainerVideo>
              <iframe
                width="560"
                height="315"
                style={{ margin: "2rem 0" }}
                src="https://www.youtube.com/embed/DNcQrRMQczg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </ContainerVideo> */}
          </CardRightEmp>
        </ContainerInfo>
      </ContainerAbout>
    </MainContainer>
  );
}

export default About;
