import furgonCortinero from "../../../Media/Img/galeriaProductos/furgonCortinero.jpg";
import furgonFrigorifico from "../../../Media/Img/galeriaProductos/furgonFrigorifico.jpg";
import furgonCargaSeca from "../../../Media/Img/galeriaProductos/furgonCargaSeca1.jpg";
import bombaCementera from "../../../Media/Img/galeriaProductos/bombaCementera.jpg";
import furgonAcanalado from "../../../Media/Img/galeriaProductos/furgonAcanalado.jpg";

import ramflaPlataforma from "../../../Media/Img/galeriaProductos/ramflaPlataforma.jpg";
import tolva10 from "../../../Media/Img/galeriaProductos/tolva10.jpg";
// import tolvaEncapsulada from "../../../Media/Img/galeriaProductos/tolvaEncapsulada.jpg";
import tolvaEncapsulada2 from "../../../Media/Img/detproductos/tolvas/tolva11.jpeg";
import tolvaGranelera from "../../../Media/Img/detproductos/tolvas/tolva10.jpeg";
import tolvaCarreta from "../../../Media/Img/galeriaProductos/tolvaCarreta.jpg";
import liviano from "../../../Media/Img/galeriaProductos/liviano.jpg";
import cisterna from "../../../Media/Img/galeriaProductos/cisterna.jpg";
///Iconos
import camion from "../../../Media/Icons/camion.png";
export const productos = [
  {
    nombre: "Tolva Roquera y Semi Roqueras",
    descripcion:
      "Especialista en trabajo pesado!. Fabricado para una maxima resistencia y cumplir con trabajos exigentes como traslados de piedras y rocas.",
    nowMore: "carrocerias/tolva-semiremolque-volquete-metalero-encapsulado",
    urlImg: tolva10,
  },
  {
    nombre: "Tolvas Encapsuladas",
    descripcion: "Fabricado para el transporte de mineral y concentrado con materiales de alta resistencia.",
    nowMore: "carrocerias/tolva-semiremolque-volquete-metalero-encapsulado",
    urlImg: tolvaEncapsulada2,
  },
  {
    nombre: "Barandas Livianas",
    descripcion: "Especialistas en fabricación de barandas livianas para minería y construcción.",
    nowMore: "carrocerias/plataformas-ramflas-baranda-rebatible",
    urlImg: liviano,
  },
  {
    nombre: "Furgones Cortineros y Contraplacados",
    descripcion:
      "Fabricamos furgones de gran resistencia para el transporte de todo tipo de mercancías con accesorios importados que garantizan su seguridad.",
    nowMore: "",
    urlImg: furgonCortinero,
  },
  {
    nombre: "Furgones Frigoríficos",
    descripcion:
      "Con los mejores componentes Inyectados con polibretano de alta calidad y acabados, tenga por seguro que somos la mejor opción en el mercado con precios competitivos.",
    nowMore: "",
    urlImg: furgonFrigorifico,
  },
  {
    nombre: "Furgones de Carga Seca",
    descripcion:
      "El furgón que se adapta al transporte de todo tipo de mercadería. Ofrecemos el mejor furgón del mercado. Máxima resistencia, mejores diseños y acabados.",
    nowMore: "",
    urlImg: furgonCargaSeca,
  },
  {
    nombre: "Tanques Cisterna",
    descripcion:
      "Elípticas o circulares para el transporte de agua, combustibles etc. Cumplimos con las normas de OSINERMING.",
    nowMore: "",
    urlImg: cisterna,
  },
  {
    nombre: "Bombonas Cementeras",
    descripcion: "Con los mejores materiales de calidad para el transporte seguro de cemento y cal a granel.",
    nowMore: "",
    urlImg: bombaCementera,
  },
  {
    nombre: "Furgón Acanalado",
    descripcion:
      "Carrocería de alta resistencia, ideal para el traslado de diversos tipos de carga, su diseño y los tipos de materiales usados en su fabricación asegura su resistencia a los diferentes tipos de trabajos a los que será sometida.",
    nowMore: "",
    urlImg: furgonAcanalado,
  },
  {
    nombre: "Plataforma con Baranda Rebatible",
    descripcion: "Ideal para paletizado y contenedores de 3 ejes suspensión neumático y muelle.",
    nowMore: "carrocerias/plataformas-ramflas-baranda-rebatible",
    urlImg: ramflaPlataforma,
  },
  {
    nombre: "Tolva Granelera",
    descripcion:
      "Diseñadas para el transporte seguro y eficiente de productos a granel. Estas tolvas graneleras ofrecen una alta capacidad de carga, durabilidad y resistencia, garantizando un desempeño óptimo en condiciones de trabajo exigentes.",
    nowMore: "carrocerias/tolva-semiremolque-volquete-metalero-encapsulado",
    urlImg: tolvaGranelera,
  },
  {
    nombre: "Tolva Carreta con Quinta Rueda",
    descripcion: "Fabricamos carretas para todo tipo de tolvas con tornamesa a billa.",
    nowMore: "",
    urlImg: tolvaCarreta,
  },
];
