export const colors = {
  mainColor: "rgb(203,156,13)",
  mainColorLight: "#FFB81C",
  mainColorRgb: " rgba(203,156,13)",
  secondColor: "#D1D1D1",
  blueColor: `#101522`,
  blueLight: `#5d6d7e`,
  gray: `#D1D1D1`,
  grayBold: `#424949`,
  grayLigth: `#f2f3f4`,
};

export const fonts = {
  main: `"Teko", sans-serif`, // `"Ubuntu", sans-serif`,
  normal: `"Rubik", serif;`,
};
