import { createGlobalStyle } from "styled-components";
import { fonts } from "./helpers/enviroments";

const GlobalStyles = createGlobalStyle`
  body {
    font-family: ${fonts.normal};
    margin: 0;
    padding: 0;
  }
  h1 {
    font-family: ${fonts.main} ;
    letter-spacing: 1.2px;
    
  }
  h2, h3, p {
    font-family: ${fonts.normal} ;
    
  }

  @media (max-width: 768px) {
    h1, h2, h3 {
        font-size: 1.5rem ;
    }
    p {
        font-size: 1rem ;
    }
  }

`;

export default GlobalStyles;
