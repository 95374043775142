import React, { useState, useEffect } from "react";
import { Link as ScrollLink, scroller } from "react-scroll";
import { animateScroll as scroll } from "react-scroll";
import {
  Nav,
  LogoLink,
  MenuIcon,
  CloseIcon,
  BarIcon,
  NavItem,
  NavLink,
  NavMenu,
  LogoImg,
  LogoContainer,
} from "./NavbarElements";
import { useLocation, useNavigate } from "react-router";
// import Dropdown from './Dropdown'
function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [scrollNav, setScrollNav] = useState(false);

  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  const handleClick = () => setClick(!click);
  const closeMovileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  const goInicio = () => {
    toggleHome();
    closeMovileMenu();
  };

  const handleNavClick = (scrollTo) => {
    if (location.pathname !== "/") {
      // Si no estamos en la página de inicio, navegamos allí
      navigate("/");
      // Aplicamos scroll al cargar
      setTimeout(() => {
        scroller.scrollTo(scrollTo, {
          duration: 500,
          smooth: true,
          offset: -80,
        });
      }, 100); // Añadimos una pequeña demora para asegurar que la página cargue
    } else {
      // Si ya estamos en la página de inicio, simplemente desplazamos
      scroller.scrollTo(scrollTo, {
        duration: 500,
        smooth: true,
        offset: -80,
      });
    }
    closeMovileMenu();
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);
  return (
    <>
      <Nav scrollNav={scrollNav}>
        <LogoContainer>
          <LogoLink to="/" onClick={toggleHome}>
            <LogoImg src="/logo.png" alt="logo" />
          </LogoLink>
          <h1>FABRICACIONES INNOVA</h1>
        </LogoContainer>

        <MenuIcon onClick={handleClick}>{click ? <CloseIcon /> : <BarIcon />}</MenuIcon>

        <NavMenu active={click}>
          <NavItem>
            <NavLink onClick={() => handleNavClick("home")}>INICIO</NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => handleNavClick("productos")}>PRODUCTOS</NavLink>
          </NavItem>

          <NavItem>
            <NavLink onClick={() => handleNavClick("empresa")}>EMPRESA</NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={closeMovileMenu}
              to="contacto"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              CONTACTO
            </NavLink>
          </NavItem>
          <NavItem></NavItem>
        </NavMenu>
      </Nav>
    </>
  );
}

export default Navbar;
