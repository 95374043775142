import React from "react";
import { TileProduct } from "./SliderElements";

function Carousel2({ activeIndex, imageSlider }) {
  return (
    <section className="carousel">
      {imageSlider.map((slide, index) => (
        <div key={index} className={index === activeIndex ? "slides active" : "inactive"}>
          <div className="boxSlides">
            <div className="boxImage">
              <img className="slide-image" src={slide.image} alt="" />
            </div>
            <div className="boxDescription">
              <div>
                <TileProduct className="slide-title">{slide.title}</TileProduct>
                <h3 className="slide-text">{slide.description}</h3>
              </div>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
}

export default Carousel2;
