import React from "react";

import {
  MainContainer,
  RightContacto,
  TitleContacto,
  WrapperContacto,
  SubTitle,
  CardContacto,
  TextContacto,
  ContainerMapa,
  BoxRedes,
  BoxRedesWsp,
  TextSocialMediaWsp,
  ContactoContainer,
  SectionTitle,
  ContactItem,
  Icon,
  Link,
} from "./contactoElements";
// import { Link } from "react-router-dom";

function Contacto() {
  // const contactoData = {
  //   Correos: ["innova.metalmec@gmail.com", "ventas@fabricaciones-innova.com"],
  //   Telefonos: { Oficina: "935 465 011", Ventas: "910 655 018", "Atención al cliente": "935 465 011" },
  //   Redes: {
  //     facebook: "https://www.facebook.com/INNOVA-Industria-Metal-Mec%C3%A1nica-EIRL-103037148924054",
  //     whatsapp: "https://wa.me/+51935465011",
  //   },
  // };
  const contactoData = {
    Correos: ["innova.metalmec@gmail.com", "ventas@fabricaciones-innova.com"],
    Telefonos: { Oficina: "935 465 011", Ventas: "910 655 018", "Atencion al cliente": "935 465 011" },
    Redes: {
      facebook: "https://www.facebook.com/INNOVA-Industria-Metal-Mec%C3%A1nica-EIRL-103037148924054",
      whatsapp: "https://wa.me/+51935465011",
    },
  };
  return (
    <MainContainer id="contacto">
      {/* <LeftContacto>
                DERECHA
            </LeftContacto> */}
      <RightContacto>
        <TitleContacto>
          <h2>CONTACTANOS</h2>
        </TitleContacto>
        <WrapperContacto>
          <CardContacto>
            <SubTitle>
              <i className="fas fa-map-marker-alt"></i> Ubicanos en:
              <TextContacto>
                <span> Asociación Aptasa AV. Canadá MZ-B 25 Cerro Colorado Arequipa, Perú.</span>
              </TextContacto>
            </SubTitle>

            <ContainerMapa>
              <iframe
                title="ubicacion"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d4686.332045642639!2d-71.59254061837746!3d-16.346688835467376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTbCsDIwJzQ5LjgiUyA3McKwMzUnMjQuOCJX!5e0!3m2!1ses!2spe!4v1729369485409!5m2!1ses!2spe"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </ContainerMapa>
          </CardContacto>
          {/* <CardContacto>
            <div>
              <SubTitle>
                <i class="fas fa-envelope"></i>Correos:
              </SubTitle>
              <TextContacto>
                <p>innova.metalmec@gmail.com </p>
              </TextContacto>
              <TextContacto>
                <p>ventas@fabricaciones-innova.com </p>
              </TextContacto>
            </div>

            <div>
              <SubTitle>
                <i class="fas fa-phone-alt"></i>Telefonos:
              </SubTitle>
              <TextContacto>
                <p>
                  <strong>Oficina:</strong> 935 465 011
                </p>
              </TextContacto>
              <TextContacto>
                <p>
                  <strong>Ventas:</strong> 910 655 018{" "}
                </p>
              </TextContacto>
              <TextContacto>
                <p>
                  <strong>Atención al cliente: </strong>935 465 011
                </p>
              </TextContacto>
              <div className="row">
                <BoxRedesWsp>
                  <TextSocialMediaWsp href="https://wa.me/+51935465011" target="_blank">
                    <i class="fab fa-whatsapp"></i> Gerencia
                  </TextSocialMediaWsp>
                </BoxRedesWsp>
                <BoxRedesWsp>
                  <TextSocialMediaWsp href="https://wa.me/+51910655018" target="_blank">
                    <i class="fab fa-whatsapp"></i> Ventas
                  </TextSocialMediaWsp>
                </BoxRedesWsp>
              </div>
            </div>
            <div>
              <SubTitle>
                <i class="fas fa-network-wired"></i>Redes:
              </SubTitle>
              <BoxRedes>
                <TextSocialMediaWsp
                  href="https://www.facebook.com/INNOVA-Industria-Metal-Mec%C3%A1nica-EIRL-103037148924054"
                  target="_blank"
                >
                  <i class="fab fa-facebook-square"></i> Facebook
                </TextSocialMediaWsp>
              </BoxRedes>
              <BoxRedesWsp>
                <TextSocialMediaWsp href="https://wa.me/+51935465011" target="_blank">
                  <i class="fab fa-whatsapp"></i>

                  <span>WhatsApp</span>
                </TextSocialMediaWsp>
              </BoxRedesWsp>
            </div>
          </CardContacto> */}
          <ContactoContainer>
            <SectionTitle>Correos</SectionTitle>
            {contactoData.Correos.map((correo, index) => (
              <ContactItem key={index}>{correo}</ContactItem>
            ))}

            <SectionTitle>Teléfonos</SectionTitle>
            {Object.entries(contactoData.Telefonos).map(([key, telefono], index) => (
              <ContactItem key={index}>
                <Link href={`https://wa.me/+51${telefono.replace(/\s+/g, "")}`} target="_blank">
                  <i className="fab fa-whatsapp" style={{ color: "#46C759" }}></i>
                  {key}: {telefono}
                </Link>
              </ContactItem>
            ))}

            <SectionTitle>Redes</SectionTitle>
            <ContactItem>
              <Link href={contactoData.Redes.facebook} target="_blank">
                <i className="fab fa-facebook-square" style={{ color: "#4267B2" }}></i>
                Facebook
              </Link>
            </ContactItem>
            <ContactItem>
              <Link href={contactoData.Redes.whatsapp} target="_blank">
                <i className="fab fa-whatsapp" style={{ color: "#46C759" }}></i>
                WhatsApp
              </Link>
            </ContactItem>
          </ContactoContainer>
        </WrapperContacto>
      </RightContacto>
    </MainContainer>
  );
}

export default Contacto;
