import styled from "styled-components";
import { colors } from "../../../helpers/enviroments";

export const MainContainer = styled.div`
  width: 90%;
  border-top: 0.7px solid #e1e1e1;
  background: #fff;
  /* box-shadow: 0px 0px 5px 6px rgb(102, 102, 102); */
  display: grid;
  grid-gap: 10px;
  margin: 20px auto;
  align-items: center;
  grid-auto-columns: minmax(auto, 1fr);
  grid-template-areas: "right right right";
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const RightContacto = styled.div`
  margin: 1rem;
  /* width: 70%; */
  /* border: 1px solid black; */
  grid-area: right;
`;
export const LeftContacto = styled.div`
  /* width: 30%; */
  margin: 1rem;
  border: 1px solid black;
  font-family: "Quicksand", sans-serif;
  grid-area: left;
  /* grid-column: 1/2; */
`;

export const TitleContacto = styled.div`
  color: rgb(194, 15, 15);
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
  margin: 30px 0px;
`;
export const WrapperContacto = styled.div`
  width: 70%;
  display: flex;
  margin: auto;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  /* padding-bottom: 2rem; */
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
export const SubTitle = styled.div`
  font-size: 1.5rem;
  font-family: "Quicksand", sans-serif;
  font-weight: bolder;
`;
export const CardContacto = styled.div`
  /* width: 20%; */
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px auto;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
export const TextContacto = styled.div`
  font-size: 20px;
  margin: 30px auto;
  font-weight: normal;
  font-family: "Quicksand", sans-serif;
`;
export const ContainerMapa = styled.div`
  width: 100%;
  box-shadow: 0px 0px 10px -1px rgb(92, 92, 102);
`;
export const BoxRedes = styled.div`
  display: flex;
  width: 140px;
  border: 1px solid #3b5998;
  border-radius: 10px;
  background-color: #3b5998;
  text-align: center;
  margin: 10px;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: #3b5998;
  }
  @media screen and (max-width: 960px) {
    width: 140px;
  }
`;
export const TextSocialMedia = styled.a`
  font-size: 20px;
  text-decoration: none;
  text-align: center;
  padding: 20px;
  font-weight: normal;
  font-family: "Quicksand", sans-serif;
  font-weight: bolder;
  color: white;
  line-height: 2.4rem;
  outline: none;

  &:hover {
    color: #3b5998;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
export const BoxRedesWsp = styled.div`
  display: flex;
  width: 140px;
  border: 1px solid #00bb2d;
  border-radius: 10px;
  background-color: #00bb2d;
  text-align: center;
  margin: 10px;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: #00bb2d;
  }
  @media screen and (max-width: 960px) {
    width: 140px;
  }
`;
export const TextSocialMediaWsp = styled.a`
  font-size: 20px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin: 5px;
  text-decoration: none;
  font-weight: normal;
  font-family: "Quicksand", sans-serif;
  font-weight: bolder;
  color: white;
  line-height: 2.4rem;
  outline: none;

  &:hover {
    color: #00bb2d;
  }

  i {
    margin-right: 3px;
  }
`;

export const ContactoContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  /* width: 300px; */
`;

export const SectionTitle = styled.h3`
  font-size: 1.2em;
  color: #333;
  margin-bottom: 10px;
`;

export const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 1em;
  color: #333;
`;

export const Icon = styled.i`
  color: #25d366;
  margin-right: 8px;
  cursor: pointer;
  margin-right: 5px;
`;

export const Link = styled.a`
  text-decoration: none;
  color: inherit;

  /* &:hover {
    color: #0077cc;
  } */

  padding: 10px;
  border-radius: 0 25px 25px 0;
  i {
    cursor: pointer;
    margin-right: 5px;
  }
  &:hover {
    background: ${colors.gray};
  }
`;
