import React, { useState } from "react";
import "./productos.css";
import camion from "../../../Media/Icons/camion.png";
// furgonFrigorifico    furgonCargaSeca tolvaEncap
import NavProductos from "./NavProductos";
// import {ModalDetProducto} from '../Productos/DetProductos/DetProductosElements'
import Modal from "./Modal/Modal";
import {
  ContenidoModal,
  WrapModal,
  WrapContent,
  WrapModalImg,
  DetContent,
  SubtitleContent,
  DetContainerDesc,
  SubtitleText,
} from "./Modal/ModalElements";
import { DataToModalProducto } from "./Modal/DataToModal";
import SlideProducto from "./CarouselProductos/SliderProducto";
import { CardProduct, LinkButton, TabButtonContainer } from "./ProductElements";
import { productos } from "./DataProduct";
import { Link } from "react-router-dom";
import { TitleEmpresa } from "../About/aboutElements";
// import { TabButton, TabButtonContainer } from "../../routes/carrocerias/carroceriaElements";
import { dataCarrocerias } from "../../routes/carrocerias/carroceriasData";
function Productos() {
  const data = dataCarrocerias;
  const [activeTab, setActiveTab] = useState(Object.entries(data)[0]);
  const [estadoModal, setEstadoModal] = useState(false);
  const [datosModal, setDatosModal] = useState({
    nombre: "",
    path: "",
    contenido: [],
  });
  const handleModal = (path) => {
    const prodEncontrado = DataToModalProducto.find((data) => data.path === path);
    setDatosModal(prodEncontrado);
    setEstadoModal(!estadoModal);
  };
  return (
    <div id="productos">
      <TabButtonContainer>
        {Object.entries(data).map(([key, value]) => (
          <LinkButton to={value.categoria.toLowerCase().concat("/").concat(key)}>{value.subcategoria}</LinkButton>
        ))}
      </TabButtonContainer>
      <div className="title-descubre">
        <h2> Descubre nuestros</h2>

        <TitleEmpresa>PRODUCTOS</TitleEmpresa>
      </div>
      <Modal estado={estadoModal} cambiarEstado={setEstadoModal} title={datosModal.nombre} icon={camion}>
        <ContenidoModal>
          <WrapModal>
            {datosModal.contenido.map((contenido) => {
              return (
                <WrapContent>
                  <SubtitleContent>
                    <SubtitleText>
                      <i className="fas fa-angle-right"></i>
                    </SubtitleText>
                    <SubtitleText>
                      <h2>{contenido.subtitulo}</h2>
                    </SubtitleText>
                  </SubtitleContent>

                  {contenido.descripcion.map((descripcion) => {
                    return (
                      <DetContent>
                        <DetContainerDesc>
                          <i className="fas fa-circle"></i>
                        </DetContainerDesc>
                        <DetContainerDesc>{descripcion}</DetContainerDesc>
                      </DetContent>
                    );
                  })}
                </WrapContent>
              );
            })}
          </WrapModal>
          <WrapModalImg>
            <SlideProducto imageSlider={datosModal.dataSlide} />
          </WrapModalImg>
        </ContenidoModal>
      </Modal>
      <NavProductos setEstadoModal={handleModal} />
      <div className="container-productos">
        {productos.map((product, index) => (
          <CardProduct className="card-producto">
            <img src={product.urlImg} alt={product.urlImg} />
            <div className="nombre-producto">
              <h2>{product.nombre}</h2>
            </div>
            <div className="descripcion-producto">
              <p>{product.descripcion}</p>
            </div>
            {product.nowMore ? (
              <div className="conocer-mas">
                <div className="">
                  <Link to={product.nowMore}>Conocer mas!</Link>
                </div>
                <div className="icon-hover">
                  <i class="fa-solid fa-hand-pointer"></i>
                </div>
              </div>
            ) : (
              ""
            )}
          </CardProduct>
        ))}
      </div>
    </div>
  );
}

export default Productos;
