import styled from "styled-components";
import { colors } from "../../helpers/enviroments";

export const SlideTitle = styled.h1`
  font-size: 32px;
  font-style: italic;
  /* font-weight: 600; */
  line-height: 28px;
  border-radius: 10px;
  display: inline-block;
  padding: 10px;
  background: ${colors.mainColor};
  color: ${colors.grayLigth};
  font-weight: bolder;
`;
export const TileProduct = styled.h1`
  font-size: 2.5rem;
  letter-spacing: 1px;
  font-style: italic;
  font-weight: 900;
`;
