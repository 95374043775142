import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Pages/Home/Home";
import Footer from "./Components/Pages/Footer/Footer";
import BtnWsp from "./Components/BtnWsp/BtnWsp";
import CarroceriaLayout from "./Components/routes/carrocerias/carroceriaLayout";
import GlobalStyles from "./globalStyle";

function App() {
  return (
    <Router>
      <GlobalStyles />
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="carrocerias/*" element={<CarroceriaLayout />} />
      </Routes>
      <Footer />
      <BtnWsp />
    </Router>
  );
}

export default App;
