import React, { Suspense } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import CarroceriaDetails from "./carroceriaDetails";
import Contacto from "../../Pages/Contacto/Contacto";

const CarroceriaLayout = () => {
  return (
    <div className="carrocerias-layout">
      {/* <Routes>
        <Route path=":tipo" element={<CarroceriaDetails />} />
      </Routes> */}
      <Suspense fallback={<div>Cargando detalles de carrocería...</div>}>
        <Routes>
          <Route path=":tipo" element={<CarroceriaDetails />} />
        </Routes>
      </Suspense>
      <Contacto />
    </div>
  );
};

export default CarroceriaLayout;
