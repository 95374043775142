import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import fondoMina from "../../../Media/Img/backgrounds/fondo_mina.jpg";
import tolvaPng from "../../../Media/Img/carrocerias/tolva-min.png";
import tolva1 from "../../../Media/Img/carrocerias/tolva1-min.png";
import tolva2 from "../../../Media/Img/carrocerias/tolva2-min.png";
import tolva3 from "../../../Media/Img/carrocerias/tolva3-min.png";
import tolva4 from "../../../Media/Img/carrocerias/tolva4-min.png";
import tolva5 from "../../../Media/Img/carrocerias/tolva5-min.png";
import tolva6 from "../../../Media/Img/carrocerias/tolva6-min.png";
import {
  ContentBlock,
  Description,
  Image,
  ImageContainer,
  MainContainer,
  Subtitle,
  SubtitleContent,
  TabButton,
  TabButtonContainer,
  TabContainer,
  TabContentContainer,
  TextContainer,
  Title,
  TitleContainer,
} from "./carroceriaElements";
import { dataCarrocerias } from "./carroceriasData";
const CarroceriaDetails = () => {
  const { tipo } = useParams();
  const data = dataCarrocerias;

  const [activeTab, setActiveTab] = useState(Object.entries(data[tipo].tabData)[0][0]);
  const product = data[tipo];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MainContainer>
      {product ? (
        <>
          <TitleContainer>
            <img src={product.imgTitleContainer} alt={product.title} loading="lazy" />
            <div className="text-overlay">
              <h1 className="title">{product.subcategoria}</h1>
              <h2>{product.subTitle}</h2>
              <p>{product.description}</p>
            </div>
            <div className="img-container">
              <img src={product.imgMain} alt="" />
            </div>
          </TitleContainer>
          <TabContainer>
            <TabButtonContainer>
              {Object.entries(product.tabData).map(([key, value]) => (
                <TabButton active={activeTab === key} onClick={() => setActiveTab(key)}>
                  {key}
                </TabButton>
              ))}
            </TabButtonContainer>

            <TabContentContainer>
              {product.tabData[activeTab].map((content, index) => (
                <ContentBlock key={index} alignRight={index % 2 === 0}>
                  <TextContainer alignRight={index % 2 === 0}>
                    <Title>{content.title}</Title>
                    <SubtitleContent>
                      <Subtitle>{content.modelo}</Subtitle>
                    </SubtitleContent>
                    <Description>
                      <i class="fa-solid fa-circle"></i>
                      {content.description}
                    </Description>
                  </TextContainer>
                  <ImageContainer>
                    <Image src={content.imgSrc} alt={content.title} />
                  </ImageContainer>
                </ContentBlock>
              ))}
            </TabContentContainer>
          </TabContainer>
        </>
      ) : (
        <p>El tipo de carrocería no está disponible.</p>
      )}
    </MainContainer>
  );
};

export default CarroceriaDetails;
