import cisterna1 from "../../../../Media/Img/detproductos/cisternas/tanqueCisterna1.png";
import cisterna2 from "../../../../Media/Img/detproductos/cisternas/tanqueCisterna3.jpg";
import cisterna3 from "../../../../Media/Img/detproductos/cisternas/tanqueCisterna5.jpg";
import camaBaja1 from "../../../../Media/Img/detproductos/camaBaja/camaBaja1.png";
import camaBaja2 from "../../../../Media/Img/detproductos/camaBaja/camaBaja2.png";
import camaBaja3 from "../../../../Media/Img/detproductos/camaBaja/camaBaja3.png";
import tolva8 from "../../../../Media/Img/detproductos/tolvas/tolva8.jpg";
import tolva9 from "../../../../Media/Img/detproductos/tolvas/tolva9.jpg";
import tolva1 from "../../../../Media/Img/detproductos/tolvas/tolva4.jpg";
import tolva2 from "../../../../Media/Img/detproductos/tolvas/tolva5.jpg";
import tolva3 from "../../../../Media/Img/detproductos/tolvas/tolva6.jpg";
import tolva7 from "../../../../Media/Img/detproductos/tolvas/tolva7.jpg";

import tolva10 from "../../../../Media/Img/detproductos/tolvas/tolva10.jpeg";
import tolva11 from "../../../../Media/Img/detproductos/tolvas/tolva11.jpeg";
import tolva12 from "../../../../Media/Img/detproductos/tolvas/tolva12.jpeg";
import tolva13 from "../../../../Media/Img/detproductos/tolvas/tolva13.jpeg";
import tolva14 from "../../../../Media/Img/detproductos/tolvas/tolva14.jpeg";
import tolva15 from "../../../../Media/Img/detproductos/tolvas/tolva15.jpeg";

import bom1 from "../../../../Media/Img/detproductos/bombonas/bom1.jpg";
import bom2 from "../../../../Media/Img/detproductos/bombonas/bom2.jpg";
import bom3 from "../../../../Media/Img/detproductos/bombonas/bom3.jpg";
import furgo1 from "../../../../Media/Img/detproductos/furgones/furgo1.jpg";
import furgo2 from "../../../../Media/Img/detproductos/furgones/furgo2.jpg";
import furgo3 from "../../../../Media/Img/detproductos/furgones/furgo3.jpg";
import furgo4 from "../../../../Media/Img/detproductos/furgones/furgon4.jpg";
import carreta1 from "../../../../Media/Img/detproductos/carretas/carreta1.jpg";
import carreta2 from "../../../../Media/Img/detproductos/carretas/carreta2.jpg";
import carreta3 from "../../../../Media/Img/detproductos/carretas/carreta3.jpg";
import ramfla2 from "../../../../Media/Img/detproductos/ramfla/baranda1.jpg";
import ramfla3 from "../../../../Media/Img/detproductos/ramfla/ramfla6.jpg";
import ramfla7 from "../../../../Media/Img/detproductos/ramfla/ramfla7.jpg";
import ramfla13 from "../../../../Media/Img/detproductos/ramfla/ramfla13.jpg";
import ramfla14 from "../../../../Media/Img/detproductos/ramfla/ramfla14.jpeg";
import ramfla15 from "../../../../Media/Img/detproductos/ramfla/ramfla15.jpeg";
import ramfla16 from "../../../../Media/Img/detproductos/ramfla/ramfla16.jpeg";

import baranda5 from "../../../../Media/Img/detproductos/baranda/baranda5.jpeg";
import baranda6 from "../../../../Media/Img/detproductos/baranda/baranda6.jpeg";
import baranda7 from "../../../../Media/Img/detproductos/baranda/baranda7.jpeg";
import baranda8 from "../../../../Media/Img/detproductos/baranda/baranda8.jpeg";

import ramfla10 from "../../../../Media/Img/detproductos/ramfla/ramfla10.jpg";
import ramfla11 from "../../../../Media/Img/detproductos/ramfla/ramfla11.jpg";
import ramfla12 from "../../../../Media/Img/detproductos/ramfla/ramfla12.jpg";
import ind1 from "../../../../Media/Img/detproductos/trabajosIndustriales/ind1.jpg";
import ind2 from "../../../../Media/Img/detproductos/trabajosIndustriales/ind2.jpg";
import ind3 from "../../../../Media/Img/detproductos/trabajosIndustriales/ind3.jpg";
import ind4 from "../../../../Media/Img/detproductos/trabajosIndustriales/ind4.jpg";
export const DataToModalProducto = [
  {
    nombre: "LINEA DE CISTERNAS",
    path: "lineaCisternas",
    contenido: [
      {
        subtitulo: "TANQUE PARA TRANSPORTE DE GLP",
        descripcion: [
          "Tanque semirremolque para transporte de GLP con equipo de despacho.",
          "Semirremolque tanque para transporte de GLP.",
        ],
      },
      {
        subtitulo: "TANQUE PARA TRANSPORTE DE COMBUSTIBLE",
        descripcion: [
          "Tanque SEMIREMOLQUE para transporte de combustible con uno o varios compartimientos.",
          "Cisterna con o sin equipo de despacho.",
        ],
      },
      {
        subtitulo: "TANQUE PARA TREANSPORTE DE AGUA",
        descripcion: [
          "Tanque para transporte de agua potable en plancha de acero inoxidable.",
          "Tanque para transporte de agua no potable con sistema de riego.",
        ],
      },
    ],
    dataSlide: [
      {
        title: "FURGONES",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: cisterna1,
      },
      {
        title: "SEMIREMOLQUES",
        description: "Contamos con una gran variedad de Remolques y Semiremolques.",
        image: cisterna2,
      },
      {
        title: "CISTERNA",
        description: "Especiales para atender necesidades de minería y construcción.",
        image: cisterna3,
      },
    ],
  },
  {
    nombre: "LINEA DE CAMA BAJAS",
    path: "lineaCamaBaja",
    contenido: [
      {
        subtitulo: "CAMA BAJA DE 4 EJES DE 70 TONELADAS",
        descripcion: [
          "Cama baja semirremolque para transporte de equipo pesado y productos en gran escala de volumen.",
        ],
      },
      {
        subtitulo: "CAMA CUNA 75 TONELADAS",
        descripcion: [
          "Cama cuna fabricado con vigas estructurales y materiales con características de alta resistencia.",
          "Componentes con suspensión neumática y mecánica.",
        ],
      },
      {
        subtitulo: "CAMA BAJA DE 3 EJES 50 TONELADAS",
        descripcion: ["Cama baja ideal para transporte de maquinarias."],
      },
    ],
    dataSlide: [
      {
        title: "FURGONES",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: camaBaja1,
      },
      {
        title: "SEMIREMOLQUES",
        description: "Contamos con una gran variedad de Remolques y Semiremolques.",
        image: camaBaja2,
      },
      {
        title: "CISTERNA",
        description: "Especiales para atender necesidades de minería y construcción.",
        image: camaBaja3,
      },
    ],
  },
  {
    nombre: "LINEA DE TOLVAS",
    path: "lineaTolvas",
    contenido: [
      {
        subtitulo: "TOLVA ENCAPSULADA DE 22 M2",
        descripcion: [
          "Para el transporte de concentrado de mineral con suspensión neumática y mecánica fabricados en plancha antiabrasiva.",
        ],
      },
      {
        subtitulo: "TOLVA CAMION VOLQUETE DE 20 M2",
        descripcion: [
          "Tolva sobrecamion fabricado con plancha HARDOX 450.",
          "Fabricamos Tolvas Constructoras de 6 mts3 hasta 20 mts3.",
          "Fabricamos Tolvas Constructoras de 6 mts3 hasta 20 mts3.",
        ],
      },
      {
        subtitulo: "TOLVA GRANELERA",
        descripcion: [
          "Fabricados especialmente para el transporte de maíz, trigo, canola, sorgo, frijol, arroz y semillas, todos ellos a granel, También se mueven fertilizantes en este tipo de equipo.",
        ],
      },
    ],
    dataSlide: [
      {
        title: "TOLVAS",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: tolva10,
      },
      {
        title: "TOLVAS",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: tolva11,
      },
      {
        title: "TOLVAS",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: tolva12,
      },
      {
        title: "TOLVAS",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: tolva13,
      },
      {
        title: "TOLVAS",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: tolva14,
      },
      {
        title: "TOLVAS",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: tolva15,
      },

      {
        title: "FURGONES",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: tolva8,
      },
      {
        title: "FURGONES",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: tolva9,
      },
      {
        title: "FURGONES",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: tolva1,
      },
      {
        title: "SEMIREMOLQUES",
        description: "Contamos con una gran variedad de Remolques y Semiremolques.",
        image: tolva2,
      },
      {
        title: "CISTERNA",
        description: "Especiales para atender necesidades de minería y construcción.",
        image: tolva3,
      },
      {
        title: "TOLVA ENCAPSULADA",
        description: "Diseñados para el transporte de minerales zinc, concentrado de cobre, carbón, abono entre otros.",
        image: tolva7,
      },
    ],
  },
  {
    nombre: "LINEA DE BOMBONAS",
    path: "lineaBombonas",
    contenido: [
      {
        subtitulo: "BOMBONA CEMENTERA",
        descripcion: ["De 32 toneladas o mayor capacidad.", "Para el transporte de cemento y cal."],
      },
      {
        subtitulo: "BOMBONA DE EMULSION",
        descripcion: [
          "Semirremolque Bombona para transporte de emulsión que cumple los estándares más exigentes de la minería nacional.",
        ],
      },
      {
        subtitulo: "CAMION MIXER",
        descripcion: ["Fabricamos camiones mixer con gran variedad de capacidad 6m³, 8m³, 10m³, 12m³."],
      },
    ],
    dataSlide: [
      {
        title: "FURGONES",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: bom1,
      },
      {
        title: "SEMIREMOLQUES",
        description: "Contamos con una gran variedad de Remolques y Semiremolques.",
        image: bom2,
      },
      {
        title: "CISTERNA",
        description: "Especiales para atender necesidades de minería y construcción.",
        image: bom3,
      },
    ],
  },
  {
    nombre: "LINEA DE FURGONES",
    path: "lineaFurgones",
    contenido: [
      {
        subtitulo: "FURGON SEMIREMOLQUE PARA CARGA SECA",
        descripcion: ["Fabricado y diseñado para todo tipo de carga seca."],
      },
      {
        subtitulo: "FURGON ACANALADO",
        descripcion: [
          "Carrocería de alta resistencia, ideal para el traslado de diversos tipos de carga, su diseño y los tipos de materiales usados en su fabricación asegura su resistencia a los diferentes tipos de trabajos a los que será sometida.",
        ],
      },
      {
        subtitulo: "FURGON ISOTERMICO",
        descripcion: [
          "Enmarcados externamente con perfiles de acero Inox calidad 304 de alta resistencia mecánica empernado y remachados a los paneles.",
        ],
      },
    ],
    dataSlide: [
      {
        title: "FURGONES",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: furgo4,
      },
      {
        title: "FURGONES",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: furgo1,
      },
      {
        title: "SEMIREMOLQUES",
        description: "Contamos con una gran variedad de Remolques y Semiremolques.",
        image: furgo2,
      },
      {
        title: "CISTERNA",
        description: "Especiales para atender necesidades de minería y construcción.",
        image: furgo3,
      },
    ],
  },
  {
    nombre: "LINEA DE CARRETAS CON 5TA RUEDA",
    path: "lineaCarretas",
    contenido: [
      {
        subtitulo: "CARRETAS PARA TOLVAS",
        descripcion: ["Fabricamos carretas todo tipo de tolvas con tornamesas billa"],
      },
      {
        subtitulo: "CARRETAS PARA CAMIONES",
        descripcion: [],
      },
    ],
    dataSlide: [
      {
        title: "SEMIREMOLQUES",
        description: "Contamos con una gran variedad de Remolques y Semiremolques.",
        image: carreta3,
      },
      {
        title: "FURGONES",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: carreta1,
      },
      {
        title: "SEMIREMOLQUES",
        description: "Contamos con una gran variedad de Remolques y Semiremolques.",
        image: carreta2,
      },
    ],
  },
  {
    nombre: "PLATAFORMA CON BARANDA REBATIBLE",
    path: "lineaRamflas",
    contenido: [
      {
        subtitulo: "PLATAFORMAS ENBARANDADOS",
        descripcion: [
          "Semirremolque Baranda Volcable. La unidad está diseñada para el transporte de carga paletizada, carga a granel, carga general y transporte de contenedores.",

          "Según necesidad del cliente.",
        ],
      },
      {
        subtitulo: "PLATAFORMAS METALERAS",
        descripcion: [
          "Para el transporte de Mercancias y/o Residuos Peligrosos.",
          "Insumos químicos y productos fiscalizados.",
        ],
      },
    ],
    dataSlide: [
      {
        title: "BARANDA",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: baranda5,
      },
      {
        title: "BARANDA",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: baranda6,
      },
      {
        title: "BARANDA",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: baranda7,
      },
      {
        title: "BARANDA",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: baranda8,
      },

      {
        title: "FURGONES",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: ramfla13,
      },
      {
        title: "SEMIREMOLQUES",
        description: "Contamos con una gran variedad de Remolques y Semiremolques.",
        image: ramfla3,
      },
      {
        title: "CISTERNA",
        description: "Especiales para atender necesidades de minería y construcción.",
        image: ramfla2,
      },
      {
        title: "BARANDA",
        description: "Especiales para atender necesidades de minería y construcción.",
        image: ramfla7,
      },
    ],
  },
  {
    nombre: "LINEA DE PLATAFORMA SEMIREMOLQUE",
    path: "lineaPlataforma",
    contenido: [
      {
        subtitulo: "SEMIREMOLQUE CON SUSPENCION NEUMATICA",
        descripcion: [
          "Fabricamos semi remolques plataformas con suspensión neumática o muelle con accesorios winches o piñas según requerimiento del cliente.",
        ],
      },
      // {
      //     subtitulo: 'RAMFLAS L',
      //     descripcion:[
      //         'Fabricamos carretas todo tipo de tolvas con tornamesas billa'
      //     ]
      // },

      // {
      //     subtitulo: 'PLATAFORMAS METALERAS',
      //     descripcion:[
      //         'Para el transporte de Mercancias y/o Residuos Peligrosos.',
      //         'Insumos químicos y productos fiscalizados.',
      //     ]
      // }
    ],
    dataSlide: [
      {
        title: "FURGONES",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: ramfla14,
      },
      {
        title: "FURGONES",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: ramfla15,
      },
      {
        title: "FURGONES",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: ramfla16,
      },

      {
        title: "CISTERNA",
        description: "Especiales para atender necesidades de minería y construcción.",
        image: ramfla11,
      },
      {
        title: "CISTERNA",
        description: "Especiales para atender necesidades de minería y construcción.",
        image: ramfla12,
      },
      {
        title: "FURGONES",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: ramfla10,
      },
    ],
  },
  {
    nombre: "LINEA DE TRABAJOS INDUSTRIALES",
    path: "lineaTrabajoIndustrial",
    contenido: [
      {
        subtitulo: "FABRICACION Y MONTAJE DE ESTRUCTURAS METALICAS",
        descripcion: [
          "Fabricación y montaje de estructuras metálicas como coberturas, techo curvo, arcos columnas y vigas, etc.",
        ],
      },
      {
        subtitulo: "SERVICIOS INDUSTRIALES.",
        descripcion: [
          "Realizamos servicios de mantenimiento, soldadura en general, apoyo a la industria y proyectos integrados.",
        ],
      },

      {
        subtitulo: "EJES LOCOS",
        descripcion: [
          "Adaptación de ejes para todo tipo de camiones.",
          "Reforzado y alargado de chasis.",
          "Trabajos de muelles",
          "Adaptación de doble corona.",
        ],
      },
    ],
    dataSlide: [
      {
        title: "FURGONES",
        description: "Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.",
        image: ind1,
      },
      {
        title: "SEMIREMOLQUES",
        description: "Contamos con una gran variedad de Remolques y Semiremolques.",
        image: ind2,
      },
      {
        title: "CISTERNA",
        description: "Especiales para atender necesidades de minería y construcción.",
        image: ind3,
      },
      {
        title: "CISTERNA",
        description: "Especiales para atender necesidades de minería y construcción.",
        image: ind4,
      },
    ],
  },
];
